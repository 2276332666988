import Signature from "./Signature"
import Policy from "./Policy"
import { xAmzDate, dateYMD } from "./Date"
import { endpoints } from "../../../../Model"

class S3FileUpload {
  static async uploadFileV2(file, config) {
    const key = `${config.dirName ? config.dirName + "/" : ""}${file.name}`;
    const contentType = file.type || "application/octet-stream"; 
  
    const dataUrl = await endpoints.getS3SignedUrl({
      Bucket: config.bucketName,
      Key: key,
      ContentType: contentType   
    });
  
    console.log("S3FileUpload::uploadFileV2", { dataUrl });
  
    if (!dataUrl.data.body.url) {
      return Promise.reject(dataUrl);
    }
  
    const { url } = dataUrl.data.body;
  
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": contentType  
      },
      body: file,
      redirect: "follow"
    };
  
    const data = await fetch(url, requestOptions);
  
    console.log("uploadFileV2", { url, requestOptions, data });
  
    if (!data.ok) return Promise.reject(data);
  
    return Promise.resolve({
      bucket: config.bucketName,
      key,
      location: `${url}`,
      result: data
    });
  }
  

  static async deleteFile(fileName, config) {
    const fd = new FormData()
    const url = `https://${config.bucketName}.s3-${
      config.region
    }.amazonaws.com/${config.dirName ? config.dirName + "/" : ""}${fileName}`
    fd.append("Date", xAmzDate)
    fd.append("X-Amz-Date", xAmzDate)
    fd.append(
      "Authorization",
      Signature.getSignature(config, dateYMD, Policy.getPolicy(config))
    )
    fd.append("Content-Type", "text/plain")

    const params = {
      method: "delete",
      headers: {
        fd
      }
    }

    const deleteResult = await fetch(url, params)
    if (!deleteResult.ok) return Promise.reject(deleteResult)
    return Promise.resolve({
      ok: deleteResult.ok,
      status: deleteResult.status,
      message: "File Deleted",
      fileName: fileName
    })
  }
}

const { deleteFile, uploadFileV2 } = S3FileUpload
export { deleteFile, uploadFileV2 }
export default S3FileUpload
